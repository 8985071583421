// Imports from respective modules
import  { DataProcessing, PDS, Preferences }  from "@mydex/pds-adaptor-frontend";
import  { MRD, Lists }  from "@mydex/mrd-adaptor-frontend";
import  { InAppDatasets }  from "@mydex/inapp-datasets";
import  { Util, Validation }   from "@mydex/utils";
import  { Dashboard }   from "@mydex/dashboards";
import  {
  Card,
  Table,
  Register,
  MaMMenu,
  CheckButtonsMulti,
  ListAddItems,
  MyPeople,
  ProfilePicWideWControls,
  FileDropper,
  PhoneEntry,
  ExpandableCard,
  MedicalInformation,
  AddressBook,
  GalleryUploader,
  MaMFormHandler
}   
from "@mydex/mydex-design-system";

// Loads code into the window context so can be references anywhere
window.DataProcessing = DataProcessing;
window.PDS = PDS;
window.Preferences = Preferences;
window.MRD = MRD;
window.Lists = Lists;
window.InAppDatasets = InAppDatasets;
window.Util = Util;
window.Validation = Validation;
window.Dashboard = Dashboard;
window.Register = Register;
window.AddressBook = AddressBook;
window.GalleryUploader = GalleryUploader;
window.MaMFormHandler = MaMFormHandler;

// Create click handlers for cards on a page. 
window.addEventListener("load", DataProcessing.load.pds, false);
window.addEventListener("load", Card.init, false);
window.addEventListener("load", Table.init, false);
window.addEventListener("load", Register.init, false);


window.addEventListener("load", MaMMenu.init, false)
window.addEventListener("load", CheckButtonsMulti.init, false);

window.addEventListener("load", ListAddItems.init, false);
window.addEventListener("load", MyPeople.init, false);
window.addEventListener("load", ProfilePicWideWControls.init, false);
window.addEventListener("load", FileDropper.init, false);
window.addEventListener("load", SmileList.init, false);
window.addEventListener("load", PhoneEntry.init, false);
window.addEventListener("load", ExpandableCard.init, false);
window.addEventListener("load", MedicalInformation.init, false);
window.addEventListener("load", AddressBook.init, false);
window.addEventListener("load", GalleryUploader.init, false);
window.addEventListener("load", MaMFormHandler.init, false);

// any ajax calls will display a loading spinner
$(document).ajaxStart(function() {
  $("#loading").show();
});

$(document).ajaxStop(function() {
  $("#loading").hide();
});
